import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
});

let maxNavbarHeight = 0;

export const updateMenuHeight = () => {
  const { navbarElement } = getElements();
  if (!navbarElement) return;

  if (navbarElement.classList.contains('navbar--open')) return;

  navbarElement?.classList.toggle(
    'navbar--scroll',
    window.scrollY > maxNavbarHeight,
  );
};

const onBreakpointChange = () => {
  if (BREAKPOINT_MEDIA_QUERIES.lg.matches) {
    maxNavbarHeight = 144;
  } else {
    maxNavbarHeight = 104;
  }
};

export default defineModule(
  () => {
    onBreakpointChange();
    updateMenuHeight();

    window.addEventListener('scroll', updateMenuHeight);

    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener('change', onBreakpointChange);
  },
  () => {
    window.removeEventListener('scroll', updateMenuHeight);
  },
);
