import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule } from '../../utils/helpers';
import { Overlay, toggleOverlay } from '../../utils/overlays';
import { updateMenuHeight } from './scroll';

const getElements = () => ({
  navbarElement: document.querySelector('.navbar'),
  navbarTogglerElement: document.querySelector('.navbar .navbar__toggler'),
});

export const toggleNavbar = (force?: boolean) => {
  const { navbarTogglerElement, navbarElement } = getElements();
  if (!navbarTogglerElement || !navbarElement) return;

  const navbarMenuElement = document.querySelector(
    `[id="${navbarTogglerElement.getAttribute('aria-controls')}"]`,
  );
  if (!navbarMenuElement) return;

  const isCurrentlyOpen = navbarElement.classList.contains('navbar--open');
  force = force ?? !isCurrentlyOpen;

  const isOpen = navbarElement.classList.toggle('navbar--open', force);
  toggleOverlay(Overlay.MENU, isOpen);

  if (isOpen) {
    navbarElement?.classList.remove('navbar--scroll');
  } else {
    updateMenuHeight();
  }

  navbarTogglerElement.ariaExpanded = `${isOpen}`;
  navbarMenuElement.ariaHidden = `${!isOpen}`;
};

const onNavbarTogglerClick = () => toggleNavbar();

const onBreakpointChange = () => {
  const { navbarElement } = getElements();
  if (!navbarElement) return;

  navbarElement.classList.add('navbar--no-transitions');
  toggleNavbar(false);
  window.requestAnimationFrame(() =>
    navbarElement.classList.remove('navbar--no-transitions'),
  );
};

export default defineModule(
  () => {
    const { navbarTogglerElement } = getElements();
    if (!navbarTogglerElement) return;

    navbarTogglerElement.addEventListener('click', onNavbarTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener('change', onBreakpointChange);
  },
  () => {
    const { navbarTogglerElement } = getElements();
    if (!navbarTogglerElement) return;

    navbarTogglerElement.removeEventListener('click', onNavbarTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      onBreakpointChange,
    );
    toggleNavbar(false);
  },
);
