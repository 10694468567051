import debounce from 'lodash-es/debounce';
import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  contentBlockElements:
    document.querySelectorAll<HTMLElement>('.content-block[id]'),
  menuItemElements:
    document.querySelectorAll<HTMLElement>('.navbar .menu-item'),
});

const setActiveMenuItem = () => {
  const { navbarElement, contentBlockElements, menuItemElements } =
    getElements();

  menuItemElements.forEach((menuItemElement) => {
    menuItemElement.classList.remove('active');
  });

  const elements = [...contentBlockElements].map((contentBlockElement) => {
    const styles = getComputedStyle(contentBlockElement);
    const rect = contentBlockElement.getBoundingClientRect();
    const marginTop = parseInt(styles.marginTop || '0');
    const marginBottom = parseInt(styles.marginBottom || '0');
    const visibleHeight =
      Math.min(
        rect.bottom + marginBottom,
        window.innerHeight - (navbarElement?.clientHeight ?? 0),
      ) - Math.max(rect.top - marginTop, 0);

    return { element: contentBlockElement, visibleHeight };
  });

  const fullyVisibleElements = elements.filter(({ element, visibleHeight }) => {
    const styles = getComputedStyle(element);
    const rect = element.getBoundingClientRect();
    const marginTop = parseInt(styles.marginTop || '0');
    const marginBottom = parseInt(styles.marginBottom || '0');
    const totalHeight = rect.height + marginTop + marginBottom;
    return visibleHeight === totalHeight;
  });

  if (fullyVisibleElements.length > 1) {
    return;
  }

  const mostVisibleElement = elements.reduce((prev, current) => {
    if (prev.visibleHeight === prev.element.offsetHeight) {
      return prev; // Fully visible element takes precedence
    }
    if (current.visibleHeight === current.element.offsetHeight) {
      return current; // Fully visible element takes precedence
    }
    return prev.visibleHeight > current.visibleHeight ? prev : current;
  }, elements[0]);

  document
    .querySelector<HTMLElement>(
      `.navbar .menu-item a[href$="#${mostVisibleElement.element.id}"]`,
    )
    ?.closest('.menu-item')
    ?.classList.add('active');
};

const onScroll = debounce(setActiveMenuItem, 100);

export default defineModule(
  () => {
    setActiveMenuItem();
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
  },
  () => {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onScroll);
  },
);
