// eslint-disable-next-line import/no-cycle
import { toggleModal, type ModalId } from '.';
import { defineModule } from '../../utils/helpers';

export const HASH_TO_MODAL_ID_MAP: Record<string, ModalId> = {
  contact: 'contact',
};

export const getModalIdFromHash = (url = window.location.href) =>
  HASH_TO_MODAL_ID_MAP[new URL(url).hash.substring(1)];

const onElementClick = (e: Event) => {
  if (!(e.target instanceof HTMLAnchorElement)) return;

  const modalId = getModalIdFromHash(e.target.href);
  if (!modalId) return;

  e.preventDefault();
  toggleModal(modalId, true);
};

export default defineModule(
  () => {
    const modalId = getModalIdFromHash();
    if (modalId) toggleModal(modalId, true);

    document.addEventListener('click', onElementClick);
  },
  () => {
    document.removeEventListener('click', onElementClick);
  },
);
