import { defineModule, isFunction } from '../utils/helpers';
declare const show_cookie_banner: unknown;
declare const cmplz_set_category_as_body_class: unknown;
export default defineModule(() => {
  try {
    if (isFunction(show_cookie_banner)) {
      show_cookie_banner();
    }
    if (isFunction(cmplz_set_category_as_body_class)) {
      cmplz_set_category_as_body_class();
    }
  } catch (err) {}
});
